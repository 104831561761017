import { Heading, HeadingProps, IconProps, Label } from '@loveholidays/design-system';
import React, { ReactElement } from 'react';

import { FilterGroup, FilterGroupProps } from './FilterGroup';
import { TooltipFilter } from './TooltipFilter/TooltipFilter';
import { Accordion } from '../Accordion/Accordion';
import { HastNode } from '@Core/types';

export interface FilterProps extends FilterGroupProps {
  title: string | ReactElement;
  titleAs: HeadingProps['as'];
  label?: string;
  footerContent?: string;
  collapsedByDefault?: boolean;
  tooltip?: HastNode;
  icon?: IconProps['name'];
  isInModal?: boolean;
}

/**
 * A `Filter` renders an accordion which contains a header and a `FilterGroup`. The accordion can be
 * collapsed thanks to the `collapsedByDefault` prop in `Accordion`.
 */
export const Filter: React.FC<FilterProps> = ({
  className,
  id,
  title,
  titleAs,
  label,
  footerContent,
  items,
  anyOptionLabel,
  selectedItemIds,
  onChange,
  collapsedByDefault = false,
  type,
  tooltip,
  isInModal = false,
}) => {
  const filterGroup = (
    <FilterGroup
      id={id}
      type={type}
      items={items}
      anyOptionLabel={anyOptionLabel}
      label={label}
      selectedItemIds={selectedItemIds}
      onChange={onChange}
      sx={{
        paddingBottom: '3xs',
      }}
    />
  );

  return tooltip ? (
    <TooltipFilter
      className={className}
      title={title}
      tooltip={tooltip}
      isInModal={isInModal}
    >
      {filterGroup}
    </TooltipFilter>
  ) : (
    <Accordion
      data-id="filter-accordion"
      collapsedByDefault={collapsedByDefault}
      className={className}
      accordionHeaderStyle={{
        paddingY: ['3xs', null, 's'],
        paddingX: 's',
      }}
      accordionHeader={
        <Heading
          variant="smallbold"
          as={titleAs}
        >
          {title}
        </Heading>
      }
      accordionFooter={
        footerContent ? (
          <Label
            sx={{ color: 'textDimmedmedium' }}
            variant="extrasmall"
          >
            {footerContent}
          </Label>
        ) : undefined
      }
      accordionFooterStyle={
        footerContent
          ? {
              paddingBottom: 's',
              paddingX: 's',
            }
          : undefined
      }
    >
      {filterGroup}
    </Accordion>
  );
};
