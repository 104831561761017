import { ModalHeader } from '@loveholidays/design-system';
import React, { FC, Fragment, PropsWithChildren, useMemo } from 'react';

import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { HastNode } from '@Core/types';
import { hastToReact } from '@UX/Hast/Hast';
import {
  TooltipHastContent,
  tooltipHastContentTitleElementName,
} from '@UX/Tooltip/TooltipHastContent';

type TooltipFilterModalProps = PropsWithChildren<{ content: HastNode }>;

export const TooltipFilterModal: FC<TooltipFilterModalProps> = ({ children, content }) => {
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const contentWithoutTitle = useMemo(
    () => ({
      ...content,
      children: content.children?.filter(
        (item) => item.tagName !== tooltipHastContentTitleElementName,
      ),
    }),
    [content],
  );
  const label = useMemo(
    () =>
      hastToReact(
        {
          ...content,
          children: content.children?.filter(
            (item) => item.tagName === tooltipHastContentTitleElementName,
          ),
        },
        {
          h3: {
            Component: ({ children }) => children.toString(),
          },
        },
      ),
    [content],
  ) as string;

  return (
    <Fragment>
      <button onClick={setModalOpen}>{children}</button>
      <LoadableModal
        show={isModalOpen}
        mobileFloating
        onClose={setModalClose}
        Header={
          <ModalHeader
            layout="small"
            onClose={setModalClose}
            label={label}
          />
        }
        Content={<TooltipHastContent content={contentWithoutTitle} />}
      />
    </Fragment>
  );
};
