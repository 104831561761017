import { Check, CheckProps, Radio, Label, keyboardOutline } from '@loveholidays/design-system';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { ControlType } from '@AuroraTypes';
import { useUpdateEffect } from '@Core/hooks/useUpdateEffect';
import { Key } from '@Core/Key';
import { FilterItemModel } from '@DesignSystemComponents/Filter/FilterItemModel';

export const filterItemTypes = ['RADIO', 'CHECKBOX'] as const;

export type FilterItemProps = Pick<FilterItemModel, 'dataId' | 'label' | 'subLabel' | 'info'> &
  Pick<CheckProps, 'checked' | 'disabled' | 'onChange'> & {
    type: ControlType;
  };

export const FilterItem: React.FC<FilterItemProps> = ({
  checked,
  disabled,
  onChange,
  label,
  subLabel,
  info,
  type,
  dataId = label,
}) => {
  const ChoiceComponent = type === 'CHECKBOX' ? Check : Radio;

  const sxStyle: SxStyleProp = {
    display: 'flex',
    paddingY: '3xs',
    paddingX: 's',
    alignItems: 'center',
    ':hover': {
      backgroundColor: 'backgroundLight',
    },
    ...(disabled
      ? {
          cursor: 'not-allowed',
          color: 'textDimmedmedium',
        }
      : {
          cursor: 'pointer',
        }),
  };

  useUpdateEffect(() => {
    // if checkbox becomes disabled, then uncheck the filter
    if (type === 'CHECKBOX' && disabled) {
      onChange(false);
    }
  }, [disabled, type]);

  return (
    <label
      data-id={`filter-item-${dataId}`}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (disabled) {
          return;
        }

        if (e.key === Key.Enter || e.key === Key.Space) {
          e.preventDefault();
          onChange(!checked);
        }
      }}
      sx={{
        ...sxStyle,
        ...keyboardOutline,
      }}
    >
      <div
        sx={{
          display: 'flex',
          marginRight: '3xs',
        }}
      >
        <ChoiceComponent
          data-id={`filter-option-${dataId}`}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
      </div>

      <div
        sx={{
          display: 'flex',
          flexFlow: 'column',
          flexGrow: 1,
          hyphens: 'auto',
          ...(disabled && {
            color: 'textDimmedmedium',
          }),
        }}
      >
        {typeof label === 'string' ? <Label variant="medium">{label}</Label> : label}
        {subLabel && (
          <Label
            variant="extrasmall"
            sx={{ color: 'textDimmedmedium' }}
          >
            {subLabel}
          </Label>
        )}
      </div>

      {!!info && (
        <Label
          variant="small"
          data-id={`filter-info-${dataId}`}
          sx={{
            marginLeft: '3xs',
          }}
        >
          {info}
        </Label>
      )}
    </label>
  );
};
