import React from 'react';

import { FilterItemModel } from './FilterItemModel';
import { ControlType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { FilterItem } from '@DesignSystemComponents/FilterItem/FilterItem';
import { LoadableFilterRange } from '@UX/components/Filter/LoadableFilterRange';
import { LoadableFilterStepper } from '@UX/components/Filter/LoadableFilterStepper';

const ANY_OPTION_ID = 'any';

export interface FilterGroupProps extends ClassNameProps {
  id: string;
  items: FilterItemModel[];
  selectedItemIds: FilterItemModel['id'][];
  type: ControlType;
  onChange: (selected?: FilterItemModel['id'] | FilterItemModel['id'][]) => void;
  anyOptionLabel?: string;
  label?: string;
}

/**
 * A `FilterGroup` renders a set of filter options. Interacting with any of the filter options
 * will result in calling `onChange` prop with the current selected filter options.
 */
export const FilterGroup: React.FC<FilterGroupProps> = ({
  id,
  type,
  className,
  items,
  selectedItemIds,
  onChange,
  anyOptionLabel,
  label,
}) => {
  const changeSelection = (id?: string | number, isSelected?: boolean) => {
    const isAny = !id || id === ANY_OPTION_ID;

    switch (type) {
      case 'CHECKBOX': {
        if (isSelected) {
          onChange(isAny ? [] : [...selectedItemIds, id]);
        } else {
          onChange(selectedItemIds.filter((i) => i !== id));
        }
        break;
      }
      default: {
        onChange(isAny ? undefined : id);
      }
    }
  };

  switch (type) {
    case 'STEPPER': {
      return (
        <LoadableFilterStepper
          sx={{
            marginBottom: '2xs',
            paddingX: 's',
          }}
          data-id={id}
          items={items}
          selected={selectedItemIds[0]}
          onChange={changeSelection}
          label={label}
        />
      );
    }
    case 'RANGE': {
      return (
        <LoadableFilterRange
          items={items}
          data-id={id}
          selected={selectedItemIds[0]}
          onChange={changeSelection}
        />
      );
    }
    default: {
      return (
        <ul
          className={className}
          data-id={`filter-group-${id}`}
        >
          {!!anyOptionLabel && (
            <li key="any-option">
              <FilterItem
                key={anyOptionLabel}
                type={type}
                label={anyOptionLabel}
                checked={!selectedItemIds.length}
                onChange={(isSelected) => changeSelection(ANY_OPTION_ID, isSelected)}
              />
            </li>
          )}

          {items.map(({ id: optionId, dataId, label, subLabel, info, disabled }) => (
            <li key={optionId}>
              <FilterItem
                type={type}
                label={label}
                subLabel={subLabel}
                info={info}
                dataId={`${id}-${dataId ?? label}`}
                checked={
                  optionId === ANY_OPTION_ID
                    ? !selectedItemIds.length
                    : selectedItemIds.includes(optionId)
                }
                disabled={disabled}
                onChange={(isSelected) => changeSelection(optionId, isSelected)}
              />
            </li>
          ))}
        </ul>
      );
    }
  }
};
